<template>
  <div class="glyphs-paginator" v-if="chars.length > 256">
    <button @click="page--" :disabled="page === 0">前へ</button>
    ページ {{ page + 1 }}/{{ Math.ceil(chars.length / 256) }}
    <button @click="page++" :disabled="page * 256 + 256 >= chars.length">次へ</button>
  </div>

  <div class="glyphs">
    <div class="glyph-container" v-for="char in block" :key="char">
      <div class="code">
        <div class="code-single" v-for="i in char.length" :key="i">
          U+{{ char.codePointAt(i - 1)?.toString(16)?.padStart(4, '0')?.toUpperCase() }}
        </div>
      </div>
      <div class="glyph" :style="{ 'font-family': fontFamily }">{{ char }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  chars: string[];
  fontFamily: string;
}>();

const page = ref(0);
const block = computed(() => props.chars.slice(page.value * 256, page.value * 256 + 256));

watch(() => props.chars, () => {
  page.value = 0;
}, { immediate: true });
</script>

<style lang="scss" scoped>
.glyphs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  background: rgba(0, 0, 0, 0.1);
  padding: 0.5em;

  .glyph-container {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
  }

  .code {
    font-size: 0.8em;
    text-align: center;
  }

  .glyph {
    font-size: 2em;
    line-height: 1em;
    background: white;
    height: 1em;
    justify-self: center;
    display: flex;
    vertical-align: middle
  }
}
</style>

import { opentypeMacintoshLanguageIDs } from './languages-mac';
import { opentypeWindowsLanguageIDs } from './languages-win';

export const opentypePlatformIDs = {
  0: 'Unicode',
  1: 'Macintosh',
  3: 'Windows',
};


export const getOpentypeLanguage = (platformID: number, languageID: number) => {
  if (platformID === 1) {
    return opentypeMacintoshLanguageIDs[languageID];
  } else if (platformID === 3) {
    return opentypeWindowsLanguageIDs[languageID];
  } else {
    return undefined;
  }
};

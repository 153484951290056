import { CmapRecord0DataView } from './cmap0';
import { CmapRecord4DataView } from './cmap4';
import { CmapRecord } from './record';

export class CmapTableDataView extends DataView {
  get version() {
    return this.getUint16(0, false);
  }

  get numTables() {
    return this.getUint16(2, false);
  }

  get records() {
    const tables: {
      platformID: number;
      encodingID: number;
      offset: number;
      format: number;
      length: number;
      record?: CmapRecord;
    }[] = [];

    const numTables = this.numTables;
    for (let i = 0; i < numTables; i++) {
      const offset = 4 + i * 8;

      const recordOffset = this.getUint32(offset + 4, false);
      const format = this.getUint16(recordOffset, false);
      const length = format === 8 ? this.getUint32(recordOffset + 4, false) : this.getUint16(recordOffset + 2, false);
      const constructor = {
        0: CmapRecord0DataView,
        4: CmapRecord4DataView,
      }[format];
      const record = constructor ? new constructor(this.buffer, this.byteOffset + recordOffset, length) : undefined;

      tables.push({
        platformID: this.getUint16(offset, false),
        encodingID: this.getUint16(offset + 2, false),
        offset: recordOffset,
        format,
        length,
        record,
      });
    }
    return tables;
  }

}

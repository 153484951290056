import { CmapRecord } from './record';

export class CmapRecord4DataView extends DataView implements CmapRecord {
  get format() {
    return this.getUint16(0, false);
  }

  get length() {
    return this.getUint16(2, false);
  }

  get language() {
    return this.getUint16(4, false);
  }

  get segCountX2() {
    return this.getUint16(6, false);
  }

  get segCount() {
    return this.segCountX2 / 2;
  }

  get searchRange() {
    return this.getUint16(8, false);
  }

  get entrySelector() {
    return this.getUint16(10, false);
  }

  get rangeShift() {
    return this.getUint16(12, false);
  }

  get reservedPad() {
    return this.getUint16(14 + this.segCountX2, false);
  }

  getEndCode(i: number) {
    return this.getUint16(14 + i * 2, false);
  }

  getStartCode(i: number) {
    return this.getUint16(16 + this.segCountX2 + i * 2, false);
  }

  getIdDelta(i: number) {
    return this.getInt16(16 + 2 * this.segCountX2 + i * 2, false);
  }

  getIdRangeOffset(i: number) {
    return this.getUint16(16 + 3 * this.segCountX2 + i * 2, false);
  }

  getGlyphIdArray(i: number) {
    return this.getUint16(16 + 4 * this.segCountX2 + i * 2, false);
  }

  get codeBlocks () {
    const codeBlocks = [];
    for (let i = 0; i < this.segCount; i++) {
      codeBlocks.push({
        start: this.getStartCode(i),
        end: this.getEndCode(i),
        idDelta: this.getIdDelta(i),
        idRangeOffset: this.getIdRangeOffset(i),
      });
    }
    return codeBlocks;
  }

  get allCodes () {
    return this.codeBlocks.flatMap(({ start, end }) => {
      const codes = [];
      for (let code = start; code <= end; code++) {
        if (code !== 0xFFFF) {
          codes.push(code);
        }
      }
      return codes;
    });
  }
}

import '@/assets/styles/main.scss';

import App from './App.vue';
import { createApp } from 'vue';
// import { SectionManager } from './composables/section';

document.title = 'WOFF Viewer';

const app = createApp(App);
// app.use(SectionManager);
app.mount(document.body);

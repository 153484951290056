<template>
  <h1>WOFF File Viewer</h1>
  <p>WOFFファイルの中身が見れる謎のツール</p>
  <p>作りかけなのでサロゲートペアとかリガチャとか異体字セレクタとかはまだ見れない</p>

  <Transition name="hovering">
    <div class="hovering" v-if="filehover">
      ドロップしてWOFFファイルを読み込み
    </div>
  </Transition>

  <div v-if="!selectedFile">
    <div><button @click="selectFile">ファイルを選択</button></div>
    <div>もしくは</div>
    <div>ファイルをドロップして読み込み</div>
  </div>

  <div v-if="selectedFile">
    <h2>ファイル情報</h2>
    <p>ファイル名: {{ selectedFile.name }}</p>
    <p>ファイルサイズ: {{ selectedFile.size.toLocaleString() }} バイト</p>
    <template v-if="woffHeader">
      <p>
        ファイルタイプ:
        <template v-if="(woffHeader instanceof WoffDataView)">
          WOFF ({{ flavors[woffHeader.flavor] ?? '不明' }})
        </template>
        <template v-else-if="(woffHeader instanceof OpenTypeDataView)">
          {{ flavors[woffHeader.sfntVersion] ?? '不明' }}
        </template>
      </p>
      <p v-if="nameTable">
        フォント名: {{ nameTable.strings[1][0].text }}
      </p>
      <p v-if="nameTable">
        著作権表記: {{ nameTable.strings[0][0].text }}
      </p>
    </template>
    <p v-else-if="loading">
      読込中です……
    </p>
    <p v-else>
      読み込まれたファイルはWOFFファイルではありません。
    </p>
  </div>

  <div v-if="cmapTable">
    <h2>コードマップ</h2>
    <div v-for="subtable, i in cmapTable.records" :key="i">
      <h3>サブテーブル {{ i }}</h3>
      <div>フォーマット: {{ subtable.format }}</div>
      <div>プラットフォーム: {{ opentypePlatformIDs[subtable.platformID as 0] ?? subtable.platformID }}</div>
      <template v-if="subtable.record">
        <div v-if="subtable.record.language">言語: {{ getOpentypeLanguage(subtable.platformID, subtable.record.language)
          }} ({{
      subtable.record.language }})</div>
        <div>コードポイント数: {{ subtable.record.allCodes.length.toLocaleString() }}</div>
        <Glyphs :chars="subtable.record.allCodes.map(x => String.fromCodePoint(x))" :fontFamily="fontFamily"
          v-if="fontFamily" />
      </template>
      <div v-else>
        <div>未対応です。ごめんね。</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { FontFileSignatureDataView, WoffDataView } from '@/woff';
import { opentypePlatformIDs, getOpentypeLanguage, FontFileDataView, NameTableDataView, CmapTableDataView, OpenTypeDataView } from '@/opentype';
import Glyphs from '@/components/Glyphs.vue';

const filehover = ref(false);
const selectedFile = ref<File>();
const woffHeader = ref<FontFileDataView>();
const nameTable = ref<NameTableDataView>();
const cmapTable = ref<CmapTableDataView>();
const loading = ref(false);

const flavors: { [x: number]: string } = {
  0x00010000: 'TrueType',
  0x4F54544F: 'OpenType(CFF)',
};

const fontFamily = ref<string>();

const loadFile = async (file: File) => {
  if (loading.value) {
    return;
  }

  try {
    loading.value = true;
    woffHeader.value = undefined;
    nameTable.value = undefined;
    cmapTable.value = undefined;

    (window as any).$file = file; // eslint-disable-line @typescript-eslint/no-explicit-any
    selectedFile.value = file;
    const buffer = await file.arrayBuffer();
    const view = (new FontFileSignatureDataView(buffer)).font;

    // const view = new WoffDataView(buffer);
    woffHeader.value = view;

    if (view == null) {
      return;
    }

    fontFamily.value = 'F_' + Math.random().toString(36).substring(7);
    const fontFace = new FontFace(fontFamily.value, `url(${URL.createObjectURL(file)})`, {});
    fontFace.load().then(x => {
      document.fonts.add(x);
    });

    if (view.tables['name']) {
      nameTable.value = await view.getNameTable();
    }

    if (view.tables['cmap']) {
      cmapTable.value = await view.getCmapTable();
    }
  } finally {
    loading.value = false;
  }
};

const selectFile = () => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = '.woff,.woff2';
  input.onchange = () => {
    if (input.files) {
      loadFile(input.files[0]);
    }
  };
  input.click();
};

onMounted(() => {
  if ((window as any).$file) { // eslint-disable-line @typescript-eslint/no-explicit-any
    loadFile((window as any).$file); // eslint-disable-line @typescript-eslint/no-explicit-any
  }
  document.addEventListener('dragover', ev => {
    if (ev.dataTransfer) {
      if (ev.dataTransfer?.types?.includes('Files')) {
        ev.preventDefault();
        ev.stopPropagation();
        if (loading.value) {
          ev.dataTransfer.dropEffect = 'none';
        } else {
          filehover.value = true;
          return;
        }
      }
    }
    filehover.value = false;
  });

  document.addEventListener('dragleave', () => {
    filehover.value = false;
  });
  document.addEventListener('drop', ev => {
    if (ev.dataTransfer) {
      if (ev.dataTransfer?.types?.includes('Files')) {
        ev.preventDefault();
        ev.stopPropagation();
        loadFile(ev.dataTransfer.files[0]);
      }
    }
    filehover.value = false;
  });
});
</script>

<style lang="scss" scoped>
.hovering {
  color: #0091EA;
  border: 2px #00B0FF dashed;
  background: transparentize(#80D8FF, 0.8);
  backdrop-filter: blur(4px);
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.hovering-enter-active,
.hovering-leave-active {
  transition: opacity 0.2s;
}

.hovering-enter-from,
.hovering-leave-to {
  opacity: 0;
}
</style>

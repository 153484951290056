export const opentypeMacintoshLanguageIDs: { [k: number]: string } = {
  0:'English',
  1:'French',
  2:'German',
  3:'Italian',
  4:'Dutch',
  5:'Swedish',
  6:'Spanish',
  7:'Danish',
  8:'Portuguese',
  9:'Norwegian',
  10:'Hebrew',
  11:'Japanese',
  12:'Arabic',
  13:'Finnish',
  14:'Greek',
  15:'Icelandic',
  16:'Maltese',
  17:'Turkish',
  18:'Croatian',
  19:'Chinese (traditional)',
  20:'Urdu',
  21:'Hindi',
  22:'Thai',
  23:'Korean',
  24:'Lithuanian',
  25:'Polish',
  26:'Hungarian',
  27:'Estonian',
  28:'Latvian',
  29:'Sami',
  30:'Faroese',
  31:'Farsi/Persian',
  32:'Russian',
  33:'Chinese (simplified)',
  34:'Flemish',
  35:'Irish Gaelic',
  36:'Albanian',
  37:'Romanian',
  38:'Czech',
  39:'Slovak',
  40:'Slovenian',
  41:'Yiddish',
  42:'Serbian',
  43:'Macedonian',
  44:'Bulgarian',
  45:'Ukrainian',
  46:'Byelorussian',
  47:'Uzbek',
  48:'Kazakh',
  49:'Azerbaijani (Cyrillic script)',
  50:'Azerbaijani (Arabic script)',
  51:'Armenian',
  52:'Georgian',
  53:'Moldavian',
  54:'Kirghiz',
  55:'Tajiki',
  56:'Turkmen',
  57:'Mongolian (Mongolian script)',
  58:'Mongolian (Cyrillic script)',
  59:'Pashto',
  60:'Kurdish',
  61:'Kashmiri',
  62:'Sindhi',
  63:'Tibetan',
  64:'Nepali',
  65:'Sanskrit',
  66:'Marathi',
  67:'Bengali',
  68:'Assamese',
  69:'Gujarati',
  70:'Punjabi',
  71:'Oriya',
  72:'Malayalam',
  73:'Kannada',
  74:'Tamil',
  75:'Telugu',
  76:'Sinhalese',
  77:'Burmese',
  78:'Khmer',
  79:'Lao',
  80:'Vietnamese',
  81:'Indonesian',
  82:'Tagalog',
  83:'Malay (Roman script)',
  84:'Malay (Arabic script)',
  85:'Amharic',
  86:'Tigrinya',
  87:'Galla',
  88:'Somali',
  89:'Swahili',
  90:'Kinyarwanda/Ruanda',
  91:'Rundi',
  92:'Nyanja/Chewa',
  93:'Malagasy',
  94:'Esperanto',
  128:'Welsh',
  129:'Basque',
  130:'Catalan',
  131:'Latin',
  132:'Quechua',
  133:'Guarani',
  134:'Aymara',
  135:'Tatar',
  136:'Uighur',
  137:'Dzongkha',
  138:'Javanese (Roman script)',
  139:'Sundanese (Roman script)',
  140:'Galician',
  141:'Afrikaans',
  142:'Breton',
  143:'Inuktitut',
  144:'Scottish Gaelic',
  145:'Manx Gaelic',
  146:'Irish Gaelic (with dot above)',
  147:'Tongan',
  148:'Greek (polytonic)',
  149:'Greenlandic',
  150:'Azerbaijani (Roman script)',
};

import { FontFileDataView } from './container-base';

export class OpenTypeDataView extends FontFileDataView {
  get sfntVersion() {
    return this.getUint32(0, false);
  }

  get numTables() {
    return this.getUint16(4, false);
  }

  get searchRange() {
    return this.getUint16(6, false);
  }

  get entrySelector() {
    return this.getUint16(8, false);
  }

  get rangeShift() {
    return this.getUint16(10, false);
  }

  getTable(i: number) {
    const offset = 12 + i * 16;

    // const checksum = this.getUint32(offset + 4, false);
    const dataOffset = this.getUint32(offset + 8, false);
    const dataLength = this.getUint32(offset + 12, false);

    return {
      tag: String.fromCharCode(this.getUint8(offset), this.getUint8(offset + 1), this.getUint8(offset + 2), this.getUint8(offset + 3)),
      getData: async () => {
        return new DataView(this.buffer, this.byteOffset + dataOffset, dataLength);
      },
    };
  }
}

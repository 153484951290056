import { CmapRecord } from './record';

export class CmapRecord0DataView extends DataView implements CmapRecord {
  get format() {
    return this.getUint16(0, false);
  }

  get length() {
    return this.getUint16(2, false);
  }

  get language() {
    return this.getUint16(4, false);
  }

  get glyphIndexArray() {
    return new Uint8Array(this.buffer, this.byteOffset + 6, 256);
  }

  get allCodes() {
    return [...this.glyphIndexArray].map((value, index) => ([index, value])).filter(([_, value]) => value).map(([index]) => index);
  }
}

import { FontFileDataView, OpenTypeDataView } from '@/opentype';
import { WoffDataView } from './dataview';

export class FontFileSignatureDataView extends DataView {
  get signature() {
    return this.getUint32(0, false);
  }

  get woff() {
    return this.signature === 0x774f4646 ? new WoffDataView(this.buffer, this.byteOffset, this.byteLength) : undefined;
  }

  get sfnt() {
    return this.signature === 0x00010000 || this.signature === 0x4F54544F ? new OpenTypeDataView(this.buffer, this.byteOffset, this.byteLength) : undefined;
  }

  get font(): FontFileDataView | undefined {
    return this.woff || this.sfnt;
  }
}

import { FontFileDataView } from '@/opentype/container-base';
import Pako from 'pako';

export class WoffDataView extends FontFileDataView {
  get signature() {
    return this.getUint32(0, false);
  }

  get flavor() {
    return this.getUint32(4, false);
  }

  get length() {
    return this.getUint32(8, false);
  }

  get numTables() {
    return this.getUint16(12, false);
  }

  get reserved() {
    return this.getUint16(14, false);
  }

  get totalSfntSize() {
    return this.getUint32(16, false);
  }

  get majorVersion() {
    return this.getUint16(20, false);
  }

  get minorVersion() {
    return this.getUint16(22, false);
  }

  get metaOffset() {
    return this.getUint32(24, false);
  }

  get metaLength() {
    return this.getUint32(28, false);
  }

  get metaOrigLength() {
    return this.getUint32(32, false);
  }

  get privOffset() {
    return this.getUint32(36, false);
  }

  get privLength() {
    return this.getUint32(40, false);
  }

  getTable(i: number) {
    const offset = 44 + i * 20;
    const dataOffset = this.getUint32(offset + 4, false);
    const dataCompLength = this.getUint32(offset + 8, false);
    const dataOrigLength = this.getUint32(offset + 12, false);
    // const origChecksum = this.getUint32(offset + 16, false);

    return {
      tag: String.fromCharCode(this.getUint8(offset), this.getUint8(offset + 1), this.getUint8(offset + 2), this.getUint8(offset + 3)),
      getData: async () => {
        if (dataCompLength === dataOrigLength) {
          return new DataView(this.buffer, this.byteOffset + dataOffset, dataOrigLength);
        } else {
          const view = Pako.inflate(new Uint8Array(this.buffer, dataOffset, dataCompLength));
          return new DataView(view.buffer, view.byteOffset, view.byteLength);
        }
      },
    };
  }
}

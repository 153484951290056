import { CmapTableDataView, NameTableDataView } from './tables';

export abstract class FontFileDataView extends DataView {
  abstract readonly numTables: number;

  abstract getTable(i: number): {
    readonly tag: string;
    getData(): Promise<DataView>;
  };

  get tables () {
    return Object.fromEntries(Array.from({ length: this.numTables }, (_, i) => this.getTable(i)).map(e => [e.tag, e.getData] as const));
  }

  async getNameTable() {
    const buffer = await this.tables.name();
    return new NameTableDataView(buffer.buffer, buffer.byteOffset, buffer.byteLength);
  }

  async getCmapTable() {
    const buffer = await this.tables.cmap();
    return new CmapTableDataView(buffer.buffer, buffer.byteOffset, buffer.byteLength);
  }
}

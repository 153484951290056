export class NameTableDataView extends DataView {
  get format() {
    return this.getUint16(0, false);
  }

  get count() {
    return this.getUint16(2, false);
  }

  get stringOffset() {
    return this.getUint16(4, false);
  }

  get strings() {
    const decoderUTF8 = new TextDecoder('utf-8');
    const decoderUTF16LE = new TextDecoder('utf-16le');
    const decoderUTF16BE = new TextDecoder('utf-16be');

    const encodingList: {[platformID: number]: TextDecoder} = {
      0: decoderUTF16LE,
      1: decoderUTF8,
      3: decoderUTF16BE,
    };

    const strings: {
      [nameID: number]: {
        platformID: number;
        encodingID: number;
        languageID: number;
        text: string;
      }[];
    } = {};

    const count = this.count;
    const stringOffset = this.stringOffset;
    for (let i = 0; i < count; i++) {
      const offset = 6 + i * 12;

      const platformID = this.getUint16(offset, false);
      const encodingID = this.getUint16(offset + 2, false);
      const languageID = this.getUint16(offset + 4, false);
      const nameID = this.getUint16(offset + 6, false);
      const length = this.getUint16(offset + 8, false);
      const nameOffset = this.getUint16(offset + 10, false) + stringOffset;

      if (!(nameID in strings)) {
        strings[nameID] = [];
      }

      strings[nameID].push({
        platformID,
        encodingID,
        languageID,
        text: encodingList[platformID].decode(new Uint8Array(this.buffer, this.byteOffset + nameOffset, length)),
      });
    }
    return strings;
  }
}
